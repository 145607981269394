import React, { useEffect, useState } from 'react';
import '../css/Dashboard.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController, // Register the LineController
  Title,
  Tooltip,
  Legend
);


const Dashboard = () => {
  const [leagueData, setLeagueData] = useState(null);
  const [rosterData, setRosterData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [matchupData, setMatchupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [selectedYear, setSelectedYear] = useState('2023');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [showLeagueAverage, setShowLeagueAverage] = useState(false);

  const leagueID = '994786689358917632'; // Replace with dynamic data or prop
  const hardcodedOwnerId = '998670246900826112'; // Owner ID HARDCODED

  const fetchLeagueData = async () => {
    try {
      const response = await fetch(`https://api.sleeper.app/v1/league/${leagueID}`);
      const data = await response.json();
      setLeagueData(data);

      const rosterResponse = await fetch(`https://api.sleeper.app/v1/league/${data.league_id}/rosters`);
      const rosterData = await rosterResponse.json();
      setRosterData(rosterData);

      const userResponse = await fetch(`https://api.sleeper.app/v1/league/${data.league_id}/users`);
      const userData = await userResponse.json();
      setUserData(userData);

      const weeks = Array.from({ length: 16 }, (_, i) => i + 1);
      const allMatchupData = [];

      for (const week of weeks) {
        const weekResponse = await fetch(`https://api.sleeper.app/v1/league/${data.league_id}/matchups/${week}`);
        const weekData = await weekResponse.json();

        weekData.forEach(matchup => {
          matchup.week = week;
        });

        allMatchupData.push(...weekData);
      }

      setMatchupData(allMatchupData);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const processRosterPositions = (positions) => {
    const positionCounts = positions.reduce((acc, position) => {
      acc[position] = (acc[position] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(positionCounts).map(([position, count]) =>
      count > 1 ? `${count} ${position}` : position
    ).join(', ');
  };

  const getScoringType = (scoringSettings) => {
    const pointsPerReception = scoringSettings.rec;

    if (pointsPerReception === 1) {
      return 'Full PPR';
    } else if (pointsPerReception === 0.5) {
      return 'Half PPR';
    } else {
      return 'Standard';
    }
  };

  const prepareChartData = () => {
    const labels = matchupDetails.map(detail => detail.week);
    const teamScores = matchupDetails.map(detail => parseFloat(detail.teamScore));
    const opponentScores = matchupDetails.map(detail => parseFloat(detail.opponentScore));
    const opponentNames = matchupDetails.map(detail => detail.opponentTeamName);

    const leagueAverageScores = matchupDetails.map((_, i) => {
      const weekScores = matchupData.filter(matchup => matchup.week === i + 1).map(matchup => matchup.points);
      return weekScores.length ? (weekScores.reduce((a, b) => a + b, 0) / weekScores.length).toFixed(2) : null;
    });

    setChartData({
      labels,
      datasets: [
        {
          type: 'line',
          label: 'Your Team Score',
          data: teamScores,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderWidth: 2,
          tension: 0.4,
          fill: false,
        },
        {
          type: 'bar',
          label: 'Opponent Score',
          data: opponentScores,
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderWidth: 2,
          tension: 0.4,
        },
        showLeagueAverage && {
          type: 'bar',
          label: 'League Average Score',
          data: leagueAverageScores,
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderWidth: 2,
          tension: 0.4,
        },
      ].filter(Boolean),
    });
  };

  useEffect(() => {
    fetchLeagueData();
  }, [selectedYear]);

  useEffect(() => {
    if (matchupData.length > 0) {
      prepareChartData();
    }
  }, [matchupData, showLeagueAverage]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data: {error.message}</div>;

  const opponentMap = {};
  matchupData.forEach((matchup) => {
    if (!opponentMap[matchup.matchup_id]) {
      opponentMap[matchup.matchup_id] = [];
    }
    opponentMap[matchup.matchup_id].push(matchup);
  });

  const rosterWithUserData = rosterData.map(roster => {
    const user = userData.find(user => user.user_id === roster.owner_id);

    const teamMatchups = matchupData.filter(matchup => matchup.roster_id === roster.roster_id);

    const totalWins = teamMatchups.filter(matchup => matchup.matchup_type === 'win').length;
    const totalLosses = teamMatchups.filter(matchup => matchup.matchup_type === 'loss').length;

    const totalPoints = teamMatchups.reduce((sum, matchup) => sum + (matchup.points || 0), 0).toFixed(2);
    const pointsAgainst = teamMatchups.reduce((sum, matchup) => sum + (matchup.points_against || 0), 0).toFixed(2);

    const highestScore = Math.max(...teamMatchups.map(matchup => matchup.points), 0);
    const lowestScore = teamMatchups.length > 0
      ? Math.min(...teamMatchups.map(matchup => matchup.points).filter(points => points !== null && points !== undefined))
      : 0;

    const teamName = user && user.metadata?.team_name
      ? user.metadata.team_name
      : user?.display_name || `Team ${roster.roster_id}`;

    return {
      ...roster,
      display_name: user ? user.display_name : 'Unknown',
      team_name: teamName,
      totalWins,
      totalLosses,
      totalPoints,
      pointsAgainst,
      highestScore,
      lowestScore,
      team_id: roster.roster_id
    };
  });

  const filteredData = selectedTeam
    ? rosterWithUserData.filter(roster => roster.roster_id === selectedTeam)
    : rosterWithUserData;

  const selectedRosterID = rosterData.find(roster => roster.owner_id === hardcodedOwnerId)?.roster_id;

  const matchupDetails = matchupData
    .filter(matchup => matchup.roster_id === selectedRosterID)
    .map(matchup => {
      const opponentMatchup = matchupData.find(
        m => m.matchup_id === matchup.matchup_id && m.week === matchup.week && m.roster_id !== selectedRosterID
      );

      const opponentRoster = rosterData.find(roster => roster.roster_id === opponentMatchup?.roster_id);
      const opponentUser = userData.find(user => user.user_id === opponentRoster?.owner_id);

      const opponentName = opponentUser?.display_name || opponentRoster?.team_name || `Roster ID: ${opponentRoster?.roster_id}` || 'Unknown User';

      return {
        week: `Week ${matchup.week}`,
        matchupNumber: matchup.matchup_id,
        opponentTeamName: opponentName,
        opponentScore: opponentMatchup?.points || 'N/A',
        teamScore: matchup.points || 'N/A',
        result: matchup.points > (opponentMatchup?.points || 0) ? 'Win' : 'Loss',
      };
    });


  // Calculate average Win margin 
  const averageWinMargin = matchupDetails
    .filter(detail => detail.result === 'Win')
    .reduce((acc, detail) => acc + (detail.teamScore - detail.opponentScore), 0) / matchupDetails.filter(detail => detail.result === 'Win').length || 0;


  // Calculate average Loss margin 
  const averageLossMargin = matchupDetails
    .filter(detail => detail.result === 'Loss')
    .reduce((acc, detail) => acc + (detail.opponentScore - detail.teamScore), 0) / matchupDetails.filter(detail => detail.result === 'Loss').length || 0;


  // Calculate average point differential
  const averagePointDifferential = 
  matchupDetails.reduce((acc, detail) => acc + (detail.teamScore - detail.opponentScore), 0) / matchupDetails.length || 0;

  return (
    <div className="dashboard-container">
      <div className="header">
        <h1>{leagueData?.name || 'League'} Overview</h1>
        <div className="season-selector">
          <label htmlFor="year">Select Year:</label>
          <select id="year" value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
            <option value="2023">2023</option>
            <option value="2022">2022</option>
            <option value="2021">2021</option>
          </select>
        </div>
        <div className="team-selector">
          <label htmlFor="team">Select Team:</label>
          <select id="team" value={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
            <option value="">All Teams</option>
            {rosterWithUserData.map(roster => (
              <option key={roster.roster_id} value={roster.roster_id}>
                {roster.team_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {leagueData && (
        <div className="league-info">
          <h2>League Info</h2>
          <ul>
            <li>League Name: {leagueData.name}</li>
            <li>League ID: {leagueData.league_id}</li>
            <li>Total Rosters: {leagueData.total_rosters}</li>
            <li>Roster Size: {processRosterPositions(leagueData.roster_positions)}</li>
            <li>Scoring Type: {getScoringType(leagueData.scoring_settings)}</li>
          </ul>
        </div>
      )}

      <div className="chart-and-metrics">
  <div className="performance-chart">
    <h2>Performance Chart</h2>
    <Bar data={chartData} options={{ maintainAspectRatio: false }} />
  </div>

  <div className="metrics">
    <h2>Calculated Metrics</h2>
    <div>
      <strong>Average Win Margin  :   </strong>
      <span className="win">
      </span>
      {averageWinMargin.toFixed(2)} points
    </div>
    <div>
      <strong>Average Loss Margin  :   </strong> 
      <span className="loss">
      </span>
      {averageLossMargin.toFixed(2)} points
    </div>
    <div>
      <strong>Average Point Differential  :    </strong> 
      <span className={averagePointDifferential >= 0 ? 'win' : 'loss'}>
        {averagePointDifferential.toFixed(2)} points</span>
    </div>
    <div>
      <label>
        <input
          type="checkbox"
          checked={showLeagueAverage}
          onChange={() => setShowLeagueAverage(!showLeagueAverage)}
        />
        Show League Average
      </label>
    </div>
  </div>
</div>


      <div className="team-list">
        <h2>League Summary</h2>
        <table>
          <thead>
            <tr>
              <th>Team ID</th>
              <th>Team Name</th>
              <th>Username</th>
              <th>Owner ID</th>
              <th>Wins</th>
              <th>Losses</th>
              <th>Total Points</th>
              <th>Points Against</th>
              <th>Division</th>
              <th>Highest Score</th>
              <th>Lowest Score</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((roster, index) => (
                <tr key={index}>
                  <td>{roster.team_id}</td>
                  <td>{roster.team_name}</td>
                  <td>{roster.display_name}</td>
                  <td>{roster.owner_id}</td>
                  <td>{roster.settings?.wins || 'N/A'}</td>
                  <td>{roster.settings?.losses || 'N/A'}</td>
                  <td>{roster.settings?.fpts || 'N/A'}</td>
                  <td>{roster.settings?.fpts_against || 'N/A'}</td>
                  <td>{roster.division_id || 'N/A'}</td>
                  <td>{roster.highestScore}</td>
                  <td>{roster.lowestScore}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">No data available for the selected team/year.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="matchup-details">
        <h2>
          Matchup Details for{' '}
          {rosterWithUserData.find(roster => roster.owner_id === hardcodedOwnerId)?.team_name || 'Unknown Team'}{' '}
          ({rosterWithUserData.find(roster => roster.owner_id === hardcodedOwnerId)?.display_name || 'Unknown User'}) 
        </h2>
        <table>
          <thead>
            <tr>
              <th>Week</th>
              <th>Matchup Number</th>
              <th>Opponent Team Name</th>
              <th>Opponent Score</th>
              <th>Your Team Score</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {matchupDetails.length > 0 ? (
              matchupDetails.map((matchup, index) => (
                <tr key={index}>
                  <td>{matchup.week}</td>
                  <td>{matchup.matchupNumber}</td>
                  <td>{matchup.opponentTeamName}</td>
                  <td>{matchup.opponentScore}</td>
                  <td>{matchup.teamScore}</td>
                  <td style={{ color: matchup.result === 'Win' ? 'green' : 'red', fontWeight: 'bold' }}>
                    {matchup.result}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No matchups available for the selected team/year.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
