import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/Navbar.css'; // Import the CSS file for styling

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <nav className="navbar">
      <div className="logo-container">
        <Link to="/" className="logo">
          World Fantasy Rank
        </Link>
      </div>
      <ul className="nav-links">
        <li className={activeLink === '/leaderboard' ? 'active' : ''}>
          <Link to="/leaderboard" onClick={() => setActiveLink('/leaderboard')}>Leaderboard</Link>
        </li>
        <li className={activeLink === '/dashboard' ? 'active' : ''}>
          <Link to="/dashboard" onClick={() => setActiveLink('/dashboard')}>Dashboard</Link>
        </li>
        <li className={activeLink === '/schedule' ? 'active' : ''}>
          <Link to="/profile" onClick={() => setActiveLink('/profile')}>Account</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
