// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Leaderboard from './components/Leaderboard';
import Navbar from './components/Navbar';
import Profile from './components/Profile';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

const theme = {
  // Your theme configuration here...
};

function AppContent() {
  const [isGuest, setIsGuest] = useState(false);
  const navigate = useNavigate();

  const handleContinueAsGuest = () => {
    setIsGuest(true);
    navigate('/leaderboard');
  };

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <div className="main-content">
        {!isGuest ? (
          <Authenticator
            components={{
              SignIn: {
                Footer: () => (
                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <button
                      onClick={handleContinueAsGuest}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#0072ce',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      Continue as Guest
                    </button>
                  </div>
                ),
              },
            }}
          >
            {({ signOut, user }) => (
              <>
                <Routes>
                  <Route path="/" element={<Navigate to="/leaderboard" />} />
                  <Route path="/leaderboard" element={<Leaderboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/profile" element={<Profile />} />
                </Routes>
                <button onClick={signOut}>Sign Out</button>
              </>
            )}
          </Authenticator>
        ) : (
          <Routes>
            <Route path="/" element={<Navigate to="/leaderboard" />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route
              path="/dashboard"
              element={
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <p>
                    To view your league data, please{' '}
                    <button
                      onClick={() => setIsGuest(false)}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#0072ce',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      Login or Create an Account
                    </button>
                  </p>
                </div>
              }
            />
            <Route
              path="/profile"
              element={
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <p>
                    To view your account information, please{' '}
                    <button
                      onClick={() => setIsGuest(false)}
                      style={{
                        padding: '10px 20px',
                        backgroundColor: '#0072ce',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      Login or Create an Account
                    </button>
                  </p>
                </div>
              }
            />
          </Routes>
        )}
      </div>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
