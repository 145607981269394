import React, { useEffect, useState } from 'react';
import '../css/Profile.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
//import { API, graphqlOperation } from 'aws-amplify'; // Ensure this import is correct
//import { getWFRUserProfile } from '../graphql/queries'; // Use the correct query name
import { Auth } from '@aws-amplify/auth';



const Profile = () => {
  const { signOut, user } = useAuthenticator();

  console.log('User Details:', user)
  console.log('Full user attributes:', user?.attributes);

  // Ensure the preferred username and other attributes are correctly extracted
  const email = user?.signInDetails?.loginId || 'Email not available';
  const preferredUsername = user?.attributes?.preferred_username || 'Preferred Username not available';
  console.log('Preferred Username:', preferredUsername);
  
  const username = user?.username || 'Username not available';

  const [sleeperID, setSleeperID] = useState('');
  const [leagues, setLeagues] = useState([]);
  const [platform, setPlatform] = useState('');
  const [leagueID, setLeagueID] = useState('');
  const [leagueUsername, setLeagueUsername] = useState('');

  const handleSleeperIDChange = (e) => setSleeperID(e.target.value);

  const handleSaveSleeperID = () => {
    console.log('Sleeper ID saved:', sleeperID);
    // Implement saving logic here
  };

  const handleAddLeague = () => {
    if (platform && leagueID && leagueUsername) {
      const newLeague = { platform, leagueID, leagueUsername };
      setLeagues([...leagues, newLeague]);
      setPlatform('');
      setLeagueID('');
      setLeagueUsername('');
    } else {
      alert('Please fill out all fields');
    }
  };

  const handleSignOut = () => signOut();

  return (
    <div className="profile-container">
      <h1>Your Profile</h1>
      <div className="profile-details">
        <img src="path-to-profile-pic.png" alt="Profile" className="profile-pic" />
        <div className="profile-info">
          <p><strong>WFR Username:</strong> {username}</p>
          <p><strong>WFR Preferred Username:</strong> {preferredUsername}</p>
          <p><strong>WFR Email:</strong> {email}</p>
          <button>Edit WFR Profile</button>
          <button onClick={handleSignOut}>Sign Out</button>
        </div>
      </div>

      <div className="league-section">
        <h2>Add a League</h2>
        <div className="league-form">
          <select value={platform} onChange={(e) => setPlatform(e.target.value)}>
            <option value="">Select Platform</option>
            <option value="Sleeper">Sleeper</option>
            <option value="Yahoo">Yahoo</option>
            <option value="ESPN">ESPN</option>
          </select>
          <input 
            type="text" 
            value={leagueID} 
            onChange={(e) => setLeagueID(e.target.value)} 
            placeholder="Enter League ID" 
          />
          <input 
            type="text" 
            value={leagueUsername} 
            onChange={(e) => setLeagueUsername(e.target.value)} 
            placeholder="Enter Username/ID" 
          />
          <button onClick={handleAddLeague}>Add League</button>
        </div>
      </div>

      {leagues.length > 0 && (
        <div className="league-list">
          <h3>Your Leagues</h3>
          <ul>
            {leagues.map((league, index) => (
              <li key={index}>
                <strong>Platform:</strong> {league.platform}, 
                <strong> League ID:</strong> {league.leagueID}, 
                <strong> Username/ID:</strong> {league.leagueUsername}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Profile;
