import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/Leaderboard.css';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const leagues = [
    { id: '923390496234459136', name: 'Dynasty Bros' },
    { id: '994786689358917632', name: 'Pocked Droppers' },
  ];

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        let allUsers = [];
        
        for (const league of leagues) {
          const rostersResponse = await axios.get(`https://api.sleeper.app/v1/league/${league.id}/rosters`);
          const rosters = rostersResponse.data;

          const userPromises = rosters.map(roster =>
            axios.get(`https://api.sleeper.app/v1/user/${roster.owner_id}`)
          );

          const usersData = await Promise.all(userPromises);
          
          // Calculate league averages for normalization
          const totalPointsFor = rosters.reduce((acc, roster) => acc + roster.settings.fpts, 0);
          const totalPointsAgainst = rosters.reduce((acc, roster) => acc + roster.settings.fpts_against, 0);
          const avgPointsFor = totalPointsFor / rosters.length;
          const avgPointsAgainst = totalPointsAgainst / rosters.length;

          const usersWithDetails = rosters.map((roster, index) => {
            const normalizedPointsFor = avgPointsFor ? roster.settings.fpts / avgPointsFor : 0;
            const normalizedPointsAgainst = avgPointsAgainst ? roster.settings.fpts_against / avgPointsAgainst : 0;

            const compositeScore = 
              (roster.settings.wins * 2) + // Wins are weighted
              (normalizedPointsFor - normalizedPointsAgainst); // Offensive and defensive adjustments

            return {
              ...roster,
              username: usersData[index].data.username,
              display_name: usersData[index].data.display_name,
              leagueName: league.name,
              compositeScore,
            };
          });

          allUsers = [...allUsers, ...usersWithDetails];
        }

        // Sort users by the composite score
        allUsers.sort((a, b) => b.compositeScore - a.compositeScore);

        setUsers(allUsers);
      } catch (error) {
        console.error("Error fetching user data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="leaderboard-container">
      <h2>Current Fantasy Rankings Across Leagues</h2>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Ranking</th>
            <th>Username</th>
            <th>Team Name</th>
            <th>League Name</th>
            <th>Wins</th>
            <th>Points For</th>
            <th>Points Against</th>
            <th>Composite Score</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{user.username}</td>
              <td>{user.display_name}</td>
              <td>{user.leagueName}</td>
              <td>{user.settings.wins}</td>
              <td>{user.settings.fpts}.{user.settings.fpts_decimal}</td>
              <td>{user.settings.fpts_against}.{user.settings.fpts_against_decimal}</td>
              <td>{user.compositeScore.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
